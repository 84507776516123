import React from "react";

const Select = ({
  type = "text",
  className = "form-control",
  setValue = null,
  value = null,
  options = [],
  ...rest
}) => {
  return (
    <select
      onChange={e => {
        if (setValue) {
          setValue(e.target.value);
        }
      }}
      value={value}
      className={className}
      {...rest}
    >
      {options.map((item, idx) => (
        <option value={item.value} key={idx}>
          {item.title}
        </option>
      ))}
    </select>
  );
};

export default Select;
