import React from "react";

const FieldCon = ({
  required = false,
  label = "",
  infoText = "",
  err = "",
  children,
  conClass = ""
}) => {
  return (
    <div className={conClass}>
      <div className="form-group">
        {label && (
          <label className="form-label">
            {label} {required && <span className="form-asterix">*</span>}
          </label>
        )}
        {children}
        <ul className="input-messages">
          {infoText && (
            <li className="text-gray">
              <i className="fas fa-info-circle mr-1"></i>
              {infoText}
            </li>
          )}
          {err && (
            <li className="text-red">
              <i className="fas fa-exclamation-triangle mr-1"></i>
              {err}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default FieldCon;
