import React from "react";
import Loader from "./Loader";

const LoadBtn = ({ children, fetching, disabled, style = {}, ...rest }) => {
  return (
    <button
      style={fetching ? { ...style, position: "relative" } : style}
      disabled={fetching || disabled}
      {...rest}
    >
      {fetching && (
        <div className="d-flex justify-content-center align-items-center abs-full">
          <Loader size={20} fill="#fff" />
        </div>
      )}
      <div style={{ visibility: fetching ? "hidden" : "visible" }}>
        {children}
      </div>
    </button>
  );
};

export default LoadBtn;
