import React from "react";
import useForm from "../hooks/form/useForm";
import Field from "./Field";
import { setValue } from "../hooks/form/formReducer";

const FieldArray = ({ conClass = "" }) => {
  const { formState, formDispatch } = useForm();

  return (
    <FieldArrayList
      conClass={conClass}
      formState={formState}
      formDispatch={formDispatch}
    />
  );
};

export const FieldArrayList = ({ formState, formDispatch, conClass }) => {
  const {
    allIds,
    inputs,
    isOptional,
    errors,
    values,
    uiProps,
    defaultUiProps
  } = formState;
  return (
    <div className={conClass}>
      {allIds.map(name => {
        return (
          <Field
            inputProps={inputs[name]}
            err={errors[name]}
            value={values[name]}
            required={!isOptional.includes(name)}
            uiProps={uiProps[name]}
            defaultUiProps={defaultUiProps}
            setValue={val => formDispatch(setValue(name, val))}
            key={name}
          />
        );
      })}
    </div>
  );
};

export default FieldArray;
